<template>
  <b-card class="px-table-0 w-100-cl-1 w-20-percent-cl-2 w-10-percent-cl-3 w-10-percent-cl-4 ">
    <b-row class="align-center">
      <b-col lg="6" style="padding-left: 33px ">
        <h2 class="mb-2"><strong>Attendance Taking</strong></h2>
      </b-col>
      <b-col lg-6>
        <div class="text-right mb-2 pr-2">
          <template v-if="attendance && attendance.length > 0">
            <b-button type="button" variant="primary" class="btn-df size-18 black" style="margin-right: 10px"
              @click="exportList">
              Export
            </b-button>
          </template>
          <template v-if="userData && (permission ? permission.edit : false)">
            <template v-if="nowDate <= endDatePro">
              <b-button type="button" variant="primary" class="btn-df size-18 black" @click="addSession">
                Add Session
              </b-button>
            </template>
            <template v-else>
              <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                Add Session
              </b-button>
            </template>

          </template>
          <template v-else>
            <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
              Add Session
            </b-button>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-table small :fields="fields_attendance" :items="attendance" tbody-tr-class="size-14 table-row" show-empty>
      <template #head()="data">
        <span class="d-block text-center">{{ data.label }}</span>
      </template>
      <template #head(no)="data">
        <span>{{ data.label }}</span>
      </template>
      <template #head(name)="data">
        <span class="text-center d-block">{{ data.label }}</span>
      </template>
      <template #cell()="data">
        <span class="d-block text-center">{{ data.value }}</span>
      </template>
      <template #cell(no)="data">
        <span>{{ data.index + 1 }}</span>
      </template>
      <template #cell(name)="data">
        <span class="customName text-center">{{ data.item.name }}</span>
      </template>
      <template #cell(timer)="data">
        <span class="text-center d-block">
          <template v-if="data.item.timer">
            {{ data.item.timer }}
          </template>
          <template v-else>
            00:00
          </template>
        </span>
      </template>
      <template #cell(timeslot)="data">
        <span class="text-center d-block">{{ data.item.from | formatDatebyMoment("HH:mm:ss", "HH:mm") }} - {{ data.item.to
          | formatDatebyMoment("HH:mm:ss", "HH:mm") }}</span>
      </template>
      <template #cell(date)="data">
        <span class="d-block text-center">{{ data.item.date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
      </template>
      <template #cell(remarks)="data">
        <span class="text-center d-block">
          <template v-if="data.item.remarks">
            <span @click="remarksOpen(data.item.name, data.item.remarks)" class="cursor-pointer text-decoration-underline"
              style="color: #8471FF">Remarks</span>
          </template>
          <template v-else>-</template>
        </span>
      </template>
      <template #cell(otp_code)="data">
        <span class="text-center d-block" style="color: #8471FF ">
          <template v-if="data.item.otp_code">
            {{ data.item.otp_code }}
          </template>
          <template v-else-if="userData && (permission ? permission.edit : false)">
            <span @click="createOTP(data.item.name, data.item.id)"
              class="cursor-pointer text-decoration-underline">Generate</span>
          </template>
          <template v-else>
            <span class="text-disabled-gray" disabled>Generate</span>
          </template>
        </span>
      </template>
      <template #cell(attendance)="data">
        <span class="text-center d-block" style="color: #8471FF ">
          <template v-if="userData && (permission ? permission.edit : false)">
            <span class="cursor-pointer mx-1" @click="editAttendance_list(data.item.id)">Edit</span>
          </template>
          <span class="cursor-pointer text-decoration-underline mx-1" @click="exportItem(data.item.id, data.item.name)"
            v-b-tooltip.hover title="Click to download">CSV</span>
        </span>
      </template>
      <template #cell(action)="data">
        <div class="d-block text-center">
          <template v-if="userData && (permission ? permission.edit : false)">

            <template v-if="data.item.status == 1">
              <span class="px-05 cursor-pointer" @click="addTime(data.item.id)">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.5 5.40039C9.95 5.40039 9.5 5.85039 9.5 6.40039V9.40039H6.5C5.95 9.40039 5.5 9.85039 5.5 10.4004C5.5 10.9504 5.95 11.4004 6.5 11.4004H9.5V14.4004C9.5 14.9504 9.95 15.4004 10.5 15.4004C11.05 15.4004 11.5 14.9504 11.5 14.4004V11.4004H14.5C15.05 11.4004 15.5 10.9504 15.5 10.4004C15.5 9.85039 15.05 9.40039 14.5 9.40039H11.5V6.40039C11.5 5.85039 11.05 5.40039 10.5 5.40039ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#8471FF" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="pauseItem(data.item.id)">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.5 2.40039C6.986 2.40039 2.5 6.88639 2.5 12.4004C2.5 17.9144 6.986 22.4004 12.5 22.4004C18.014 22.4004 22.5 17.9144 22.5 12.4004C22.5 6.88639 18.014 2.40039 12.5 2.40039ZM12.5 20.4004C8.089 20.4004 4.5 16.8114 4.5 12.4004C4.5 7.98939 8.089 4.40039 12.5 4.40039C16.911 4.40039 20.5 7.98939 20.5 12.4004C20.5 16.8114 16.911 20.4004 12.5 20.4004Z"
                    fill="#DD3E47" />
                  <path d="M9.5 9.40039H15.5V15.4004H9.5V9.40039Z" fill="#DD3E47" />
                </svg>
              </span>
              <span class="px-05">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                    fill="#B1B1B1" />
                </svg>
              </span>
              <span class="px-05">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                    fill="#B1B1B1" />
                </svg>
              </span>
            </template>
            <template v-else-if="data.item.status == 0 || data.item.status == 2">
              <span class="px-05">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.5 5.40039C9.95 5.40039 9.5 5.85039 9.5 6.40039V9.40039H6.5C5.95 9.40039 5.5 9.85039 5.5 10.4004C5.5 10.9504 5.95 11.4004 6.5 11.4004H9.5V14.4004C9.5 14.9504 9.95 15.4004 10.5 15.4004C11.05 15.4004 11.5 14.9504 11.5 14.4004V11.4004H14.5C15.05 11.4004 15.5 10.9504 15.5 10.4004C15.5 9.85039 15.05 9.40039 14.5 9.40039H11.5V6.40039C11.5 5.85039 11.05 5.40039 10.5 5.40039ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#B1B1B1" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="playItem(data.item.id)">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.3 14.3004L13.97 10.8004C14.24 10.6004 14.24 10.2004 13.97 10.0004L9.3 6.50039C8.97 6.25039 8.5 6.49039 8.5 6.90039V13.9004C8.5 14.3104 8.97 14.5504 9.3 14.3004ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#29D3B5" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="editSession(data.item.id)">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                    fill="#8471FF" />
                </svg>
              </span>
              <template v-if="(userData && (permission ? permission.edit : false))">

                <span class="px-05 cursor-pointer" @click="showDeleteAction(data.item.id, data.item.name)">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#DD3E47" />
                  </svg>
                </span>

              </template>
              <template v-else>
                <span class="px-05">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#B1B1B1" />
                  </svg>
                </span>
              </template>
            </template>
            <template v-else>
              <span class="px-05">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.5 5.40039C9.95 5.40039 9.5 5.85039 9.5 6.40039V9.40039H6.5C5.95 9.40039 5.5 9.85039 5.5 10.4004C5.5 10.9504 5.95 11.4004 6.5 11.4004H9.5V14.4004C9.5 14.9504 9.95 15.4004 10.5 15.4004C11.05 15.4004 11.5 14.9504 11.5 14.4004V11.4004H14.5C15.05 11.4004 15.5 10.9504 15.5 10.4004C15.5 9.85039 15.05 9.40039 14.5 9.40039H11.5V6.40039C11.5 5.85039 11.05 5.40039 10.5 5.40039ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#B1B1B1" />
                </svg>
              </span>
              <span class="px-05">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.3 14.3004L13.97 10.8004C14.24 10.6004 14.24 10.2004 13.97 10.0004L9.3 6.50039C8.97 6.25039 8.5 6.49039 8.5 6.90039V13.9004C8.5 14.3104 8.97 14.5504 9.3 14.3004ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#B1B1B1" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="editSession(data.item.id)">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                    fill="#8471FF" />
                </svg>
              </span>
              <template v-if="(userData && (permission ? permission.edit : false))">

                <span class="px-05 cursor-pointer" @click="showDeleteAction(data.item.id, data.item.name)">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#DD3E47" />
                  </svg>
                </span>

              </template>
              <template v-else>
                <span class="px-05">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#B1B1B1" />
                  </svg>
                </span>
              </template>
            </template>

          </template>
          <template v-else>
            <span class="px-05">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.5 5.40039C9.95 5.40039 9.5 5.85039 9.5 6.40039V9.40039H6.5C5.95 9.40039 5.5 9.85039 5.5 10.4004C5.5 10.9504 5.95 11.4004 6.5 11.4004H9.5V14.4004C9.5 14.9504 9.95 15.4004 10.5 15.4004C11.05 15.4004 11.5 14.9504 11.5 14.4004V11.4004H14.5C15.05 11.4004 15.5 10.9504 15.5 10.4004C15.5 9.85039 15.05 9.40039 14.5 9.40039H11.5V6.40039C11.5 5.85039 11.05 5.40039 10.5 5.40039ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
            <span class="px-05">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.3 14.3004L13.97 10.8004C14.24 10.6004 14.24 10.2004 13.97 10.0004L9.3 6.50039C8.97 6.25039 8.5 6.49039 8.5 6.90039V13.9004C8.5 14.3104 8.97 14.5504 9.3 14.3004ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
            <span class="px-05">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
            <span class="px-05">
              <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
          </template>
        </div>
      </template>
      <template #empty="scope">
        <h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
      </template>
    </b-table>
    <b-modal id="add-Session" ref="modal_addAttendance" title="Add Activity Session" hide-header-close ok-title="Save"
      centered no-close-on-backdrop>
      <b-row style="padding-right: 20px ">
        <b-col lg="6" class="px-0">
          <b-form-group label="Session Name" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <b-form-input v-model="attendanceName" placeholder="Name" :formatter="length100"></b-form-input>
            <template v-if="require_show">
              <small class="text-danger" v-if="!attendanceName">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Timeslot From" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="timeSlotFrom" label="time" :options="getListTimeFrom" placeholder="00:00"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!timeSlotFrom">This field is required.</small>
            </template>

          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Date" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <flat-pickr v-model="date_from" placeholder="Select a date" class="form-control"
              :config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: minScheduleDate }" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!date_from">This field is required.</small>
            </template>

          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Timeslot To" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="timeSlotTo" label="time" :options="getListTimeTo" placeholder="00:00"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!timeSlotTo">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="12" class="px-0">
          <b-form-group label="Remarks" label-cols-xl="2" label-cols-md="102" label-class="text-lg-right bold"
            class="star-required">
            <b-form-textarea v-model="attendanceRemarks" placeholder="Remarks" rows="2"
              :formatter="length100"></b-form-textarea>
            <template v-if="require_show">
              <small class="text-danger" v-if="!attendanceRemarks">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveAttendance">
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal id="edit-Session" ref="modal_editSession" title="Edit Activity Session" hide-header-close ok-title="Save"
      centered no-close-on-backdrop>
      <b-row style="padding-right: 20px ">
        <b-col lg="6" class="px-0">
          <b-form-group label="Session Name" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <b-form-input v-model="itemDetail.name" placeholder="Name" :formatter="length100"></b-form-input>
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.name">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Timeslot From" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="itemDetail.from" label="time" :options="getListTimeFrom_edit" placeholder="00:00"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.from">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Date" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <flat-pickr v-model="itemDetail.date" placeholder="Select a date" class="form-control"
              :config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d' }" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.date">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Timeslot To" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="itemDetail.to" label="time" :options="getListTimeTo_edit" placeholder="00:00"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.to">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="12" class="px-0">
          <b-form-group label="Remarks" label-cols-xl="2" label-cols-md="102" label-class="text-lg-right bold"
            class="star-required">
            <b-form-textarea v-model="itemDetail.remarks" placeholder="Remarks" rows="2"
              :formatter="length100"></b-form-textarea>
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.remarks">This field is required.</small>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveEditAttendance">
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal id="remarks-open" ref="modal_remarks" :title="this.remarks_name" centered hide-header-close>
      <b-row style="padding-right: 20px ">
        <b-col>
          <b-form-group label="Remarks" label-cols-lg="2" label-class="text-right bold pt-5px-im" class="mb-0">
            <b-form-textarea v-model="remarks_tam" placeholder="Remarks" rows="1"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modalList" ref="attendance_list" title="Edit Gift" hide-header ok-title="Save" centered>
      <div class="table-custom w-15-percent-cl-1 w-40-percent-cl-2">
        <b-table small :fields="fields_attendance_list" :items="attendance_list" tbody-tr-class="size-14 table-row"
          show-empty @head-clicked="headClicked">
          <template #head()="data">
            <span class="d-block text-center">{{ data.label }}</span>
          </template>
          <template #head(id)="data">
            <span>{{ data.label }}</span>
            <span :class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
              style="position: relative; left: 20px;">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </template>
          <template #head(full_name)="data">
            <span>{{ data.label }}</span>
          </template>
          <template #cell(full_name)="data">
            <span class="maxTwoRow">{{ data.item.full_name }}</span>
          </template>
          <template #cell(id)="data">
            <span v-if="data.field.key == sort.field && sort.type == 'desc'">
              {{ total - data.index }}
            </span>
            <span v-else>
              {{ data.index + 1 }}
            </span>
          </template>
          <template #cell(status)="data">
            <span class="d-block text-center">
              <template v-if="data.item.check_in_timeline_id == null || data.item.check_in_timeline_id == ''"><span
                  style="color: #000000">Not Checked-in</span></template>
              <template v-else><span style="color: #000000">Checked-in</span></template>
            </span>
          </template>
          <template #cell(action)="row">

            <b-form-checkbox v-model="row.item.status" name="check-button" switch class="text-center switch-cb"
              value="Yes" unchecked-value="No"
              @change="changeCheckIn(row.item.full_name, row.item.check_in_timeline_id, row.item.id_timeline, row.item.activity_id, row.item.user_id, row.item.status)">

            </b-form-checkbox>

          </template>
          <template #row-details="row"></template>
          <template #empty="scope">
            <h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
          </template>
        </b-table>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>

import Datepicker from 'vuejs-datepicker'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const moment = require('moment')

export default {
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    endDatePro: {
      type: String,
      required: false,
      default: () => ''
    },
    permission: {
      type: [Array, String, Number, Object],
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      fields_attendance: [
        { key: 'no', label: 'Session' },
        { key: 'name', label: 'Session Name' },
        { key: 'date', label: 'Date' },
        { key: 'timeslot', label: 'Timeslot' },
        { key: 'remarks', label: 'Remarks' },
        { key: 'otp_code', label: 'OTP' },
        { key: 'timer', label: 'Timer' },
        { key: 'attendance', label: 'Attendance' },
        { key: 'action', label: 'Actions' },
      ],
      attendance: [],

      attendanceName: null,
      attendanceRemarks: null,
      timeSlotFrom: null,
      timeSlotTo: null,
      date_from: null,
      require_show: false,

      itemDetail: [],

      nowDate: null,
      remarks_tam: null,
      remarks_name: null,

      fields_attendance_list: [
        { key: 'id', label: 'No.' },
        { key: 'full_name', label: 'Participant Name' },
        { key: 'status', label: 'Status' },
        {
          key: 'action', label: 'Editable Action'
        },
      ],
      attendance_list: [],
      sort: { "field": "id", "type": "asc" },
      total: 0,
      ischeckInAttendance: false,
      fields: [
        { key: 'first_name', label: 'first name' },
        { key: 'last_name', label: 'last name' },
        { key: 'details', label: 'details' },
      ],
      items: [
        { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        {
          isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson', _showDetails: true
        },
      ],
      id_timeline_edit_tam: null
    }
  },
  computed: {
    minScheduleDate() {
      var d = new Date()
      d.setDate(d.getDate())
      var now = moment(d).format('YYYY-MM-DD')
      return now
    },
    getListTimeFrom() {
      let times = [];
      for (let i = 0; i < 24; i++) {

        let moment1 = this.date_from ? moment(this.date_from) : moment()

        let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time1)) {
          times.push({
            time: time1.format('HH:mm'),
            value: time1.format('HH'),
            value_s: time1.format('mm')
          })
        }
        let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time2)) {
          times.push({
            time: time2.format('HH:mm'),
            value: time2.format('HH'),
            value_s: time1.format('mm')
          })
        }
      }
      return times
    },
    getListTimeTo() {
      let times = [];
      for (let i = 0; i < 24; i++) {

        let moment1 = this.date_from ? moment(this.date_from) : moment()

        let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time1)) {
          times.push({
            time: time1.format('HH:mm'),
            value: time1.format('HH'),
            value_s: time1.format('mm')
          })
        }
        let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time2)) {
          times.push({
            time: time2.format('HH:mm'),
            value: time2.format('HH'),
            value_s: time1.format('mm')
          })
        }
      }
      return times
    },
    getListTimeFrom_edit() {
      let times = [];
      for (let i = 0; i < 24; i++) {

        let moment1 = this.itemDetail.date ? moment(this.itemDetail.date) : moment()

        let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time1)) {
          times.push({
            time: time1.format('HH:mm'),
            value: time1.format('HH'),
            value_s: time1.format('mm')
          })
        }
        let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time2)) {
          times.push({
            time: time2.format('HH:mm'),
            value: time2.format('HH'),
            value_s: time1.format('mm')
          })
        }
      }
      return times
    },
    getListTimeTo_edit() {
      let times = [];
      for (let i = 0; i < 24; i++) {

        let moment1 = this.itemDetail.date ? moment(this.itemDetail.date) : moment()

        let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time1)) {
          times.push({
            time: time1.format('HH:mm'),
            value: time1.format('HH'),
            value_s: time1.format('mm')
          })
        }
        let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
        if (moment().isSameOrBefore(time2)) {
          times.push({
            time: time2.format('HH:mm'),
            value: time2.format('HH'),
            value_s: time1.format('mm')
          })
        }
      }
      return times
    },
  },
  created() {
    this.fetchList()
    this.nowDate = moment().format("YYYY-MM-DD")
  },
  methods: {
    length100(e) {
      return String(e).substring(0, 100)
    },
    fetchList() {
      this.$store
        .dispatch('project/listAttendance', {
          activity_id: this.$route.params.activity_id
        })
        .then(response => {
          this.attendance = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportList() {
      this.$store.dispatch('project/exportAttendance', {
        id: this.$route.params.activity_id
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Attendance-list.csv') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportItem(id, title) {
      this.$store.dispatch('project/exportAttendance', {
        id: this.$route.params.activity_id,
        timeline_id: id
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title + '.csv') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    detailItem(id) {
      this.$store
        .dispatch('project/detailAttendance', {
          id: id
        })
        .then(response => {
          this.itemDetail = response.data.data

          let timeslotTo_new
          let timeslotFrom_new

          timeslotTo_new = this.itemDetail.to.split(':')
          timeslotTo_new.pop()

          timeslotFrom_new = this.itemDetail.from.split(':')
          timeslotFrom_new.pop()

          this.itemDetail.to = timeslotTo_new.join(':')
          this.itemDetail.from = timeslotFrom_new.join(':')

        })
        .catch((error) => {
          console.log(error)
        })
    },
    addSession() {
      this.require_show = false
      this.attendanceName = null
      this.attendanceRemarks = null
      this.timeSlotFrom = null
      this.timeSlotTo = null
      this.date_from = null
      this.$refs['modal_addAttendance'].show()
    },
    editSession(id) {
      this.require_show = false
      this.detailItem(id)
      this.$refs['modal_editSession'].show()
    },
    saveAttendance() {
      if (this.attendanceName && this.timeSlotFrom && this.timeSlotTo && this.date_from && this.attendanceRemarks) {
        let datas = {
          name: this.attendanceName,
          from: this.timeSlotFrom.time,
          to: this.timeSlotTo.time,
          date: this.date_from,
          remarks: this.attendanceRemarks,
          activity_id: this.$route.params.activity_id
        }
        // console.log('datas: ', datas)
        this.$store
          .dispatch('project/addAttendanceTime', datas)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.attendanceName + ' Created!',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.$refs['modal_addAttendance'].hide()
            this.fetchList()
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
      else {
        this.require_show = true
      }
    },
    createOTP(title, id) {
      this.$bvModal.msgBoxConfirm('Please confirm that you want to create OTP.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2 modal-delete',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store
              .dispatch('project/createOTP', {
                id: id
              })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Create OTP Successfully',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-center'
                  }
                )
                this.fetchList()
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    showDeleteAction(id, title) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'outline-danger',
        footerClass: 'p-2 modal-delete',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store
              .dispatch('project/deleteAttendance', {
                id: id
              })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: title + ' Has been Deleted!',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-center'
                  })

                this.fetchList()
              })
              .catch((error) => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-center'
                  })
              })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    saveEditAttendance() {

      if (this.itemDetail.name && this.itemDetail.date && this.itemDetail.to && this.itemDetail.from && this.itemDetail.remarks) {
        let datas_edit = {
          name: this.itemDetail.name,
          from: this.itemDetail.from.time ? this.itemDetail.from.time : this.itemDetail.from,
          to: this.itemDetail.to.time ? this.itemDetail.to.time : this.itemDetail.to,
          date: this.itemDetail.date,
          remarks: this.itemDetail.remarks,
        }
        // console.log('datas: ', datas_edit)
        this.$store
          .dispatch('project/editAttendance', {
            id: this.itemDetail.id,
            data: datas_edit
          })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.itemDetail.name + ' Updated!',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.$refs['modal_editSession'].hide()
            this.fetchList()
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
      else {
        this.require_show = true
      }
    },
    playItem(id) {
      this.$store
        .dispatch('project/startProActivity', {
          id: id
        })
        .then(response => {
          this.fetchList()
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    pauseItem(id) {
      this.$store
        .dispatch('project/pauseProActivity', {
          id: id
        })
        .then(response => {
          this.fetchList()
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    addTime(id) {
      this.$store
        .dispatch('project/addTimeProActivity', {
          id: id
        })
        .then(response => {
          this.fetchList()
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    remarksOpen(name, remark) {
      this.remarks_name = name
      this.remarks_tam = remark
      this.$refs['modal_remarks'].show()
    },
    editAttendance_list(id) {
      this.id_timeline_edit_tam = id
      this.sort = { "field": "id", "type": "asc" }
      this.$refs['attendance_list'].show()
      this.getlistAttendancePart(id)
    },
    getlistAttendancePart(id) {
      this.$store
        .dispatch('project/listPartiTimeline', {
          id: id,
          sort: this.sort
        })
        .then(response => {
          this.attendance_list = response.data.data
          this.total = response.data.data.length
          // console.log('attendance edit list: ', response.data.data)
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    headClicked(key) {
      if (this.sort.field == key) {
        if (this.sort.type == 'asc') this.sort.type = 'desc'
        else this.sort.type = 'asc'
      } else {
        this.sort.field = key
        this.sort.type = 'desc'
      }
      this.getlistAttendancePart(this.id_timeline_edit_tam)
    },
    changeCheckIn(title, check_in_timeline_id, id_timeline, id_activity, id_user, status) {
      console.log('item: ', status)
      if (status == 'Yes') {
        this.$store
          .dispatch('project/changeStatusCheckIn_Attendance', {
            id_timeline: id_timeline,
            user_id: id_user,
            activity_id: id_activity
          })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: title + ' has been updated to Not Checked-in status',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.editAttendance_list(id_timeline)
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
      else {
        // Checkin to not
        this.$store
          .dispatch('project/changeStatusCheckIn_Attendance', {
            check_in_timeline_id: check_in_timeline_id
          })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: title + ' has been updated to Checked-in status',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.editAttendance_list(id_timeline)
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
    }
  }
}

</script>
<style>
#add-Session .modal-dialog,
#edit-Session .modal-dialog {
  max-width: 74%;
}

#modalList .modal-dialog {
  max-width: 700px;
}

#remarks-open .modal-dialog {
  max-width: 60%
}

.customName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-disabled-gray {
  color: #b8c2cc !important;
}
</style>