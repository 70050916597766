<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col>
				<Breadcrumb :labels="labels" :subLabel_2_click="subLabel_name" />
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom" v-model="tabActivity">
			            <b-tab title="Basic Info" class="overview-tab" rel="basic_rel">	
			            	<validation-observer ref="form_rel" #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20 hasshadow" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6" style="padding-left: 33px ">
							                <h2 class="mb-2"><strong>Basic Info</strong></h2>
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">

							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelEdit" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px " v-if="!isEditing">Cancel</b-button>

							                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveEdit" v-if="!isEditing">
							                        Save
							                    </b-button>	
							                    <template v-else-if="isEditing">
							                    	<template v-if="(userData && userData && (permission ? permission.edit : false))">
									        			<b-button type="button" variant="primary" class="btn-df size-18 black"  @click="editAction">
										                    Edit
										                </b-button>
									        		</template>
									        		<template v-else>
									        			<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                    Edit
										                </b-button>
									        		</template>	             
									        	</template>		                    

							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Activity Name" label-cols-lg="4" class="star-required input-group-label"> 
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Activity Name"
							                            rules="required"                      
							                        >       
					                                    <b-form-input
					                                    	v-model="detail.name"
					                                      	placeholder="Activity Name"
					                                      	:disabled="isDisabled"
															:formatter="length100"
					                                    ></b-form-input>
					                                	<small class="text-danger">{{ errors[0] }}</small>
                          							</validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Product Code" label-cols-lg="4" class="star-required input-group-label">    
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Product Code"
							                            rules="required"                      
							                        >                        
					                                    <b-form-input
					                                    	v-model="detail.product_code"
					                                      	placeholder="Product Code"
					                                      	:disabled="isDisabled"
															:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Project" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Project"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="getProjectName"
						                                    label="title"
						                                    :options="selectProject"
						                                    placeholder="Project"
						                                    disabled
						                                    id="disabled-field"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>         
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Service Type" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Service Type"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedService"
						                                    label="name"
						                                    :options="selectService"
						                                    placeholder="Service"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	      
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Owner" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Owner"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedOwner"
						                                    label="full_name"
						                                    :options="selectActivityOwner"
						                                    placeholder="Select Owner"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>    
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Reference No." label-cols-lg="4" class="input-group-label">   
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Reference No"                   
							                        >                          
					                                    <b-form-input
					                                    	v-model="detail.reference_no"
					                                      	placeholder="Ref No."
					                                      	:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>     
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Status" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Status"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedStatus"
						                                    label="title"
						                                    :options="selectActivityStatus"
						                                    placeholder="Select Status"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Mode of Delivery" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Mode of Delivery"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedMode"
						                                    label="title"
						                                    :options="selectMode"
						                                    placeholder="Select a mode"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>                
					                            </b-form-group>
				                          	</b-col>                         	
				                          	<b-col lg="12" class="px-0">
												<b-form-group label="Venue" label-cols-lg="2" class="input-group-label">
													<b-form-input
														v-model="detail.venue"
														placeholder="Venue"
														:disabled="isDisabled"
														:formatter="length50"
													></b-form-input>
												</b-form-group>
					                            <!-- <template v-if="selectedType.value == 1">
					                            	 <b-form-group v-if="detail.trained != null" label="Trained (Indirect)" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="detail.trained"
						                                    placeholder="Venue"
						                                    :disabled="isDisabled"
						                                ></b-form-input>
						                            </b-form-group>
						                            <b-form-group v-else label="Trained (Indirect)" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="detail.venue"
						                                    placeholder="Venue"
						                                    :disabled="isDisabled"
						                                ></b-form-input>
						                            </b-form-group>
				                          		</template>
					                            <template v-else>
					                            	 <b-form-group v-if="detail.venue != null" label="Venue" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="detail.venue"
						                                    placeholder="Venue"
						                                    :disabled="isDisabled"
						                                ></b-form-input>
						                            </b-form-group>
						                            <b-form-group v-else label="Venue" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="detail.trained"
						                                    placeholder="Venue"
						                                    :disabled="isDisabled"
						                                ></b-form-input>
						                            </b-form-group>
					                            </template>		 -->
					                            <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col>  
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Start Date" label-cols-lg="4" class="star-required">
					                            	<div class="calendarIcon">
						                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
						                        	</div>
					                                <flat-pickr
					                                	v-model="detail.commencement_date"
					                                	placeholder="Select a date"
				                                      	class="form-control fieldCalendar"           
				                                      	:config="{ altInput: true , altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: detailProject.commencement_date, maxDate: detailProject.completion_date }"
				                                      	:disabled="isDisabled"
				                                    />
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="End Date" label-cols-lg="4" class="star-required">
					                            	<div class="calendarIcon">
						                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
						                        	</div>
					                                <flat-pickr
					                                	v-model="detail.completion_date"
				                                      	class="form-control fieldCalendar"           
				                                      	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: detailProject.commencement_date, maxDate: detailProject.completion_date}"
				                                      	placeholder="Select a date"
				                                      	:disabled="isDisabled"
				                                    />
					                            </b-form-group>
				                          	</b-col> 	       
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Start Date Remarks" label-cols-lg="4">
					                            	<b-form-textarea
					                            		v-model="detail.commencement_remark"
					                                    placeholder="Brief Description"
					                                    rows="4"
					                                    :disabled="isDisabled"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="End Date Remarks" label-cols-lg="4">
					                            	<b-form-textarea
					                            		v-model="detail.completion_remark"
					                                    placeholder="Brief Description"
					                                    rows="4"
					                                    :disabled="isDisabled"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col>  			                          	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Type" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Type"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedType"
						                                    label="title"
						                                    :options="activityType"
						                                    placeholder="Select type"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>    
				                          	<b-col lg="6" class="px-0"></b-col>
				                          	<template v-if="selectedType">
				                          		<template v-if="selectedType.value == 1">
				                          			<b-col lg="6" class="px-0">   
				                          				    
							                            <b-form-group label="Leaders Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">
							                            	<template v-if="detail.leaders_trained_direct != null">
								                            	<b-form-input
								                            		v-model="detail.leaders_trained_direct"
								                                    placeholder="0"
								                                    disabled
								                                ></b-form-input>
							                                </template>
							                                <template v-else>
								                            	<b-form-input
								                            		v-model="detail.trainer_trained_direct"
								                                    disabled
								                                ></b-form-input>
								                            </template>
							                            </b-form-group>
						                          	</b-col> 	     
						                          	<b-col lg="6" class="px-0">             
							                            <b-form-group label="Leaders Trained (Indirect)" label-cols-lg="4" class="input-group-label">
							                            	<b-form-input
							                            		v-model="detail.number_people_indirect"
																:disabled="isDisabled"
							                                ></b-form-input>
							                            </b-form-group>
						                          	</b-col> 
				                          		</template>
					                          	<template v-else>
					                          		<b-col lg="6" class="px-0"> 
				                          				<b-form-group label="Specialists Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">
				                          					<template v-if="detail.trainer_trained_direct != null">
								                            	<b-form-input
								                            		v-model="detail.trainer_trained_direct"
								                                    disabled
								                                ></b-form-input>
								                            </template> 
								                            <template v-else>
								                            	<b-form-input
								                            		v-model="detail.leaders_trained_direct"
								                                    placeholder="0"
								                                    disabled
								                                ></b-form-input>
						                          			</template>      
							                            </b-form-group>	         
						                          	</b-col> 	     
						                          	<b-col lg="6" class="px-0">             
							                            <b-form-group label="Specialists Trained (Indirect)" label-cols-lg="4" class="input-group-label">
							                            	<b-form-input
							                            		v-model="detail.number_people_indirect"
							                                    placeholder="0"
							                                    :disabled="isDisabled"
							                                ></b-form-input>
							                            </b-form-group>
						                          	</b-col> 
					                          	</template>
					                        </template>
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Activity Description" label-cols-lg="2">
					                            	<b-form-textarea
					                            		v-model="detail.description"
					                                    placeholder="Description"
					                                    rows="4"
					                                    :disabled="isDisabled"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col>  
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Activity Remarks" label-cols-lg="2">
					                            	<b-form-textarea
					                            		v-model="detail.remarks"
					                                    placeholder="Remarks"
					                                    rows="4"
					                                    :disabled="isDisabled"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col>  
				                      	</b-row>
				                      	<div class="d-lg-flex">
				                          	<div>				                              
				                          	</div>
				                      	</div>  
					                </div>
					            </b-form> 
					        </validation-observer>
					        <Attendace :permission="permission" :endDatePro="this.endDatePro" />
					        <Evaluation :permission="permission" :endDatePro="this.endDatePro" ref="eva_ref" />
			            </b-tab>
			            <b-tab title="Gifts">
			            	<Gifts :permission="permission" />
			            </b-tab>
			            <b-tab title="Documents" class="tab-child tab-pt-0">
			            	<Documents :permission="permission" />
			            </b-tab>    
			            <b-tab title="Users" class="tab-child">
			            	<User :permission="permission" />
			            </b-tab>                        
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>

<script>

	const moment = require('moment')

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import Attendace from './attendance/List'
    import Evaluation from './evaluation/List'
    import Gifts from './gifts/List'
    import Documents from './document/List'
    import User from './users/List'
	import globalAdmin from '../../../model/globalAdmin'
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,

          	Attendace,
          	Evaluation,

          	Gifts,
          	Documents,
          	User,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				permission: [],
		    	projectOwner: null,
		    	isEditing: true,
            	isDisabled: true,
            	disableField: 'disabled-field',	
				labels: { "our-portfolio-project-detail": "" },
			    subLabel_name: {"our-portfolio-project-detail-edit-editactivity": ''},

			    activityType: [
			    	{title: 'Specialists', value: 0},
                	{title: 'Leaders', value: 1},                	
                ],
                selectedType: {title: 'Select type', value: null},

                selectProject: [],   
                getProjectName: null,

                selectService: [],
		        selectedService: null,

                selectActivityOwner: [],
                selectedOwner: null,

                selectedStatus: {title: 'Select status', value: null},
                selectActivityStatus: [
                  { title: 'Not Done', value: 0 },
                  { title: 'In Progress ', value: 1 },
                  { title: 'Done', value: 2 },
                  { title: 'Terminated', value: 3 },
                ],

                selectedMode: {title: 'Select a mode', value: null},
                selectMode: [
                  { title: 'Online', value: 0},
                  { title: 'Face to Face', value: 1 },
                ],

                detail: [],

				detailProject: [],

                tabActivity: 0,
                endDatePro: null,
            }
		},
	    watch: {
	    	"$route": function(val) {
				if(val){
					if(this.$route.params){
						if(this.$route.name == 'our-portfolio-project-detail-edit-editactivity' ) {
							this.labels = { "our-portfolio-project-detail-edit-editactivity": "" }
							this.getTitle()
						}
					}
				}
			},
	        tabActivity: function(val) {
	        	if(val == 0) {
	        		this.$refs.eva_ref.listUsersExpert()
	        	}                
            },
			permissions:function(val){
				this.permission = val
			}
	    },
		created(){
			this.permission = this.checkPermissionOurPortfolio(this.userData,"Project",this.$route.params.activity_id,"Activity") ?? []
			this.fetchCountryID()
			this.getNameProject()
			this.listOwner()
			this.listServicesSetup()

			this.fetchItem()

			if(this.$route.name == 'our-portfolio-project-detail-edit-editactivity' ){
				this.labels = { "our-portfolio-project-detail-edit-editactivity": "" }
				this.getTitle()
			}
			if(this.$route && this.$route.query && this.$route.query.tab) this.tabActivity = Number(this.$route.query.tab)
		},
		computed: mapState({
			permissions: state => state.auth.permissionOurPortfolio,
		}),
		methods: {
			length50(e){
	          	return String(e).substring(0,50)
	      	},
			length20(e){
	          	return String(e).substring(0,20)
	      	},
	      	length100(e){
	          	return String(e).substring(0,100)
	      	}, 
			length1000(e){
	          	return String(e).substring(0,1000)
	      	}, 
			...mapActions({
				checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',   
			}),
			fetchCountryID(){
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['our-portfolio-project-detail'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getTitle(){
				this.$store
	            .dispatch('project/detailActivityItem', {
	              	id: this.$route.params.activity_id  
	            })
	            .then(response => {
	            	this.labels['our-portfolio-project-detail-edit-editactivity'] = response.data.data.name
	            	document.title = response.data.data.name + ' - Activity Listing - ITEES | Your Global Partner of Choice'	
	            })
	            .catch((error) => {
	              console.log(error)
	            })
			},
			fetchItem(){
				this.$store
	            .dispatch('project/detailActivityItem', {
	              	id: this.$route.params.activity_id  
	            })
	            .then(response => {
	            	this.detail = response.data.data

	            	this.tam = response.data.data.name

	            	if(this.detail.service_type) this.getServicebyId(this.detail.service_type)
	            	if(this.detail.user_id)	this.getOwnerbyId(this.detail.user_id)

	            	if(this.detail.status == 0) {
						this.selectedStatus.title = 'Not Done'
						this.selectedStatus.value = 0
					}
					if(this.detail.status == 1) {
						this.selectedStatus.title = 'In Progress'
						this.selectedStatus.value = 1
					}
					if(this.detail.status == 2) {
						this.selectedStatus.title = 'Done'
						this.selectedStatus.value = 2
					}
					if(this.detail.status == 3) {
						this.selectedStatus.title = 'Terminated'
						this.selectedStatus.value = 3
					}

					if(this.detail.mode_of_delivery == 0) {
						this.selectedMode.title = 'Online'
						this.selectedMode.value = 0
					}
					if(this.detail.mode_of_delivery == 1) {
						this.selectedMode.title = 'Face to Face'
						this.selectedMode.value = 1
					}

					if(this.detail.activity_type == 0) {
						this.selectedType.title = 'Specialists'
						this.selectedType.value = 0
					}
					if(this.detail.activity_type == 1) {
						this.selectedType.title = 'Leaders'
						this.selectedType.value = 1
					}					

					this.projectOwner = this.detail.permission_edit
	              	// console.log('detail activity: ', response.data.data)

	              	this.endDatePro = this.detail.completion_date
	            })
	            .catch((error) => {
	              console.log(error)
	            })
			},
			getNameProject(){
				this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {
					this.subLabel_name['our-portfolio-project-detail-edit-editactivity'] = response.data.data.name
					this.getProjectName = response.data.data.name
					this.detailProject = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getServicebyId(id){
				this.$store
				.dispatch('auth/detailServicesSetup', {
					id: id
				})
				.then(response => {
					this.selectedService = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getOwnerbyId(id){
				this.$store
				.dispatch('auth/detailStaff', {
					id: id
				})
				.then(response => {
					this.selectedOwner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {
		            	"group":"1",
						"active": "1"
		            }
		        })
				.then(response => {
					this.selectActivityOwner = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},			
			listServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {	
					filter: {
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 1
					    }
					  ]
					}
				})
				.then(response => {
					this.selectService = response.data.data
					// console.log('this.selectService: ', this.selectService)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			editAction(){
				this.isDisabled = false
	          	this.isEditing = false
	          	this.disableField= ''
			},
			cancelEdit(){
				this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}`, query: {tab: 1} })
			},
			saveEdit(){
				this.$refs.form_rel.validate().then(success => {
            		if (success){

            			let data_edit_Activity = {}

            			if( this.selectedType &&  this.selectedType.value == 1 ){ // leader
            				data_edit_Activity = {
			                    name: this.detail.name,
			                    product_code: this.detail.product_code,
			                    project_id: this.$route.params.project_id,
			                    service_type: this.selectedService ? this.selectedService.id : null,
			                    owner: this.selectedOwner ? this.selectedOwner.id : null, 
			                    reference_no: this.detail.reference_no,
			                    status: this.selectedStatus ? this.selectedStatus.value : null,
			                    mode_of_delivery: this.selectedMode ? this.selectedMode.value : null,
			                    commencement_date: this.detail.commencement_date,
			                    commencement_remark: this.detail.commencement_remark,
			                    completion_date: this.detail.completion_date,
			                    completion_remark: this.detail.completion_remark,
			                    activity_type: this.selectedType ? this.selectedType.value : null,
			                    description: this.detail.description,
			                    remarks: this.detail.remarks,
			                    number_people_indirect: this.detail.number_people_indirect,
			                    venue: this.detail.venue ? this.detail.venue : null,
			                }
            			}
            			else{
            				data_edit_Activity = {
			                    name: this.detail.name,
			                    product_code: this.detail.product_code,
			                    project_id: this.$route.params.project_id,
			                    service_type: this.selectedService ? this.selectedService.id : null,
			                    owner: this.selectedOwner ? this.selectedOwner.id : null, 
			                    reference_no: this.detail.reference_no,
			                    status: this.selectedStatus ? this.selectedStatus.value : null,
			                    mode_of_delivery: this.selectedMode ? this.selectedMode.value : null,
			                    commencement_date: this.detail.commencement_date,
			                    commencement_remark: this.detail.commencement_remark,
			                    completion_date: this.detail.completion_date,
			                    completion_remark: this.detail.completion_remark,
			                    activity_type: this.selectedType ? this.selectedType.value : null,
			                    description: this.detail.description,
			                    remarks: this.detail.remarks,
			                    number_people_indirect: this.detail.number_people_indirect,
			                    venue: this.detail.venue ? this.detail.venue : null,
			                }
            			}
            			
		                // console.log('data_edit_Activity: ', data_edit_Activity)

		                this.$store
		                .dispatch('project/editActivity', {
		                	id: this.$route.params.activity_id,
		                	data_edit: data_edit_Activity
		                })
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                            title: this.detail.name + ' Updated!',
		                            variant: 'success',
		                        },
		                    },
		                    {
		                        position: 'top-center'
		                    })

		                	this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}`, query: {tab: 1} })
		                	
		                })
		            	.catch((error) => {
		                  	console.log(error)
		                  	this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
		                })  

            		}
            	})
			}
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#modalPopover .modal-dialog, #modalUsers .modal-dialog{
		max-width: 650px;
	}
	.gift-table table thead tr th:first-child{
		min-width: 61px
	}
	.gift-table table thead tr th:last-child{
		min-width: 110px
	}
	#modalPopover .modal-dialog .modal-header, #modalUsers .modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#modalPopover .modal-dialog .row-container, #modalUsers .modal-dialog .row-container{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#modalPopover .modal-dialog .modal-body, #modalUsers .modal-dialog .modal-body{
		padding-top: 30px;
    	padding-bottom: 0;
	}
	#modalPopover .modal-dialog .modal-footer, #modalUsers .modal-dialog .modal-footer{
		padding-bottom: 30px; 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>