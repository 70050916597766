<template>
	<section class="py-modal-body-0">
		<b-tabs card class="caption-add table-custom">
			<b-tab title="Participants">
				<b-card
					class="px-table-0 mt-1 w-100-cl-1 w-17-percent-cl-2 w-17-percent-cl-5 w-17-percent-cl-3 w-17-percent-cl-4 w-15-percent-cl-5 wrap-anywhre">
					<b-row class="align-center">
						<b-col lg-6>
							<div class="text-right mb-2 pr-2">
								<template
									v-if="userData && (permission ? permission.edit : false)">

									<b-button type="button" variant="primary" class="btn-df size-18 black" @click="addItem">
										Add Participant
									</b-button>

								</template>
								<template v-else>
									<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray"
										disabled>
										Add Participant
									</b-button>
								</template>
							</div>
						</b-col>
					</b-row>
					<b-table small :fields="fields_users" :items="users" tbody-tr-class="size-14 table-row cursor-pointer"
						show-empty @head-clicked="headClicked" @row-clicked="detailParticipant">
						<template #head(id)="data">
							<span>{{ data.label }}</span>
							<span
								:class="['cursor-pointer iconsort', { sort: data.field.key == user_ex_sort.sort_field && user_ex_sort.sort_type == 'desc' }]"
								style="position: relative; left: 15px;">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
									<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</span>
						</template>
						<template #head()="data">
							<span class="d-block text-center">{{ data.label }}</span>
						</template>
						<template #head(name)="data">
							<span>{{ data.label }}</span>
						</template>
						<template #head(status)="data">
							<span class="d-block text-center">{{ data.label }}</span>
						</template>
						<template #cell(id)="data">
							<span>{{ data.item.id }}</span>
						</template>
						<template #cell(name)="data">
							<span class="maxTwoRow">{{ data.item.name }}</span>
						</template>
						<template #cell(organisations)="data">
							<span class="organisations">{{ data.item.organisations }}</span>
						</template>
						<template #cell(status)="data">
							<span class="d-block text-center" v-if="data.item.status == 0">Inactive</span>
							<span class="d-block text-center" v-else="data.item.status == 1">Active</span>
						</template>
						<template #cell(activities)="data">
							<div class="d-block text-center">
								<div class="text-center d-block cursor-pointer decoration-hover"
									@click.stop="showList(data.item.user_id)" style="color: #8471FF">
									List
								</div>
							</div>
						</template>
						<template #cell(action)="data">
							<template v-if="userData && (permission ? permission.edit : false)">
								<span class="d-block text-center cursor-pointer"
									@click.stop="showDeleteAction(data.item.id, data.item.name)">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
											fill="#DD3E47" />
									</svg>
								</span>
							</template>
							<template v-else>
								<span class="d-block text-center">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
											fill="#B1B1B1" />
									</svg>
								</span>
							</template>
						</template>
						<template #cell()="data">
							<span class="d-block text-center">{{ data.value }}</span>
						</template>
						<template #empty="scope">
							<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No
								data</h4>
						</template>
					</b-table>
				</b-card>
				<div v-if="this.total > 0" class="paging-cus relative">
					<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" aria-controls="active-table"
						first-number last-number class="just-center"></b-pagination>
					<div class="limit-per">
						<select v-model="selected" class="cursor-pointer">
							<option v-for="limit in limitpage">{{ limit }}</option>
						</select>
					</div>
				</div>
				<b-modal ref="add_item" title="Add Participant" :no-close-on-backdrop="true" id="addItem_modal">
					<b-row style="padding-right: 30px ">
						<b-col lg="12" class="px-0">
							<b-form-group label="Select Organisation" label-cols-xl="4" label-cols-md="4"
								label-class="text-lg-right bold label-30-width">
									<v-select 
									v-model="selectedOrganisation"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									deselect-label="Can't remove this value"
									label="name" 
									:options="optionsOrganisation" 
									placeholder="Select Organisation"
									class="timebox form-control px-0 border-none h-auto" />
							</b-form-group>
							<b-form-group label="Select Participants" label-cols-xl="4" label-cols-md="4"
								label-class="text-lg-right bold label-30-width">
								<validation-provider #default="{ errors }" name="Select Participants" vid="participants"
									rules="required">
									<v-select 
									v-model="selectedStaff"
									:close-on-select="false"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									multiple label="full_name" 
									:options="optionsStaff" 
									placeholder="Select Participants"
									class="timebox form-control px-0 border-none h-auto" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="12" class="px-0">
							<b-form-group label="Participants Selected" label-cols-xl="4" label-cols-md="4"
								label-class="text-lg-right bold label-30-width">
								<div class="staff-selected disabled-field">
									<div v-if="selectedStaff">
										{{ selectedStaff.map(item => item.full_name).join(", ") }}
									</div>
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<template #modal-footer="{ cancel }">
						<b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelItem">
							Cancel
						</b-button>
						<b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveItem">
							Save
						</b-button>
					</template>
				</b-modal>
			</b-tab>
			<b-tab title="Experts">
				<expertTab :permission="permission" />
			</b-tab>
		</b-tabs>
		<b-modal id="modalListUser" ref="modal_Users" title="Edit Gift" hide-header ok-title="Save" centered>
			<div class="table-custom">
				<b-table small :fields="fields_participants_list" :items="participants_list"
					tbody-tr-class="size-14 table-row" show-empty>
					<template #head(action)="data">
						<span class="d-block text-center">{{ data.label }}</span>
					</template>
					<template #cell(no)="data">
						{{ data.index + 1 }}
					</template>
					<template #cell(action)="data">
						<b-link
							:href="`/admin/our-portfolio/project/${$route.params.id}/edit/${$route.params.project_id}/activity/${data.item.activity_id}`"
							target="_blank" class="d-block text-center">
							<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M13.501 16H2.50098C1.39641 16 0.500977 15.1046 0.500977 14V3C0.500977 1.89543 1.39641 1 2.50098 1H6.50098V3H2.50098V14H13.501V10H15.501V14C15.501 15.1046 14.6055 16 13.501 16ZM8.20098 9.707L6.79098 8.293L13.084 2H9.50098V0H16.501V7H14.501V3.415L8.20098 9.707Z"
									fill="#8471FF" />
							</svg>
						</b-link>
					</template>
					<template #empty="scope">
						<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data
						</h4>
					</template>
				</b-table>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default" @click="cancel()">
					Close
				</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import expertTab from './experts/List'

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		vSelect,
		Multiselect,
		expertTab
	},
	props: {
		// per_owner: {
		// 	type: Boolean,
		// 	required: false,
		// 	default: () => ''
		// },
		permission: {
			type: [Array, String, Number, Object],
		}
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			fields_users: [
				{ key: 'id', label: 'ID.' },
				{ key: 'name', label: 'Fullname' },
				{ key: 'email', label: 'Email' },
				{ key: 'organisations', label: 'Organisation' },
				{ key: 'position', label: 'Position Level' },
				{ key: 'status', label: 'User Status' },
				{ key: 'activities', label: 'Activities' },
				{ key: 'action', label: 'Actions' },
			],
			users: [],

			fields_participants_list: [
				{ key: 'no', label: 'No.' },
				{ key: 'activity_name', label: 'Activity Listing' },
				{ key: 'action', label: 'Actions' },
			],
			participants_list: [],
			userData: JSON.parse(localStorage.getItem('userData')),
			user_ex_sort: { "sort_field": "id", "sort_type": "asc" },

			dir: 'ltr',
			selectedStaff: [],
			selectedOrganisation: {},
			optionsStaff: [],
			optionsOrganisation: [],
			staff_selected_array: [],

			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',

			tam: [],
			tam2: []
		}
	},
	mounted() {
		this.selected = '10'
	},
	watch: {
		currentPage(val) {
			this.fetchList()
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.fetchList()
			}
		},
		selectedOrganisation: function(val){
			this.listParticipant(val)
		},
		optionsOrganisation: function(val){
			this.selectedOrganisation = val?.[0]
		}
	},
	created() {
		this.fetchList()
		// this.listParticipant()
		this.listOrganisation()
	},
	methods: {
		fetchList() {
			this.$store
				.dispatch('project/listUsers', {
					filter: { "activity_id": this.$route.params.activity_id },
					limit: this.perPage,
					page: this.currentPage,
					sort: this.user_ex_sort
				})
				.then(response => {
					this.users = response.data.data.data
					this.total = response.data.data.total
					// console.log('User participant: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listOrganisation(){
			this.$store
				.dispatch('auth/Client_Organisation_List')
				.then(response => {
					this.optionsOrganisation = response.data.data
					
				})
				.catch((error) => {
					console.log(error)
				})
		},
		headClicked(key) {
			if (this.user_ex_sort.sort_field == key) {
				if (this.user_ex_sort.sort_type == 'asc') this.user_ex_sort.sort_type = 'desc'
				else this.user_ex_sort.sort_type = 'asc'
			} else {
				this.user_ex_sort.sort_field = key
				this.user_ex_sort.sort_type = 'desc'
			}
			this.fetchList()
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id, title) {
			this.$store
				.dispatch('project/deleteUserAssign', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
					this.fetchList()
					this.listParticipant(this.selectedOrganisation)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		showList(id) {
			this.listUsers(id)
			this.$refs['modal_Users'].show()
		},
		listUsers(id) {
			this.$store
				.dispatch('project/listActivityParticipant', {
					user_id: id,
					project_id: this.$route.params.project_id
				})
				.then(response => {
					this.participants_list = response.data.data
					// console.log('this.participants_list: ', this.participants_list)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listParticipant(organisation) {
			this.$store
				.dispatch('project/listPart', {
					filter: { "list_search": [{ "field": "status", "keyword": "1" },{ "field": "organisation", "keyword": organisation?.id || -1 }] }
				})
				.then(response => {
					this.tam = response.data.data.map(item => item)
					this.tam2 = this.tam.filter(
						val => !this.users.find(arr1Obj => arr1Obj.user_id === val.id)
					)
					this.optionsStaff = this.tam2
				})
				.catch((error) => {
					console.log(error)
				})
		},
		addItem() {
			this.selectedStaff = null
			this.staff_selected_array = []
			this.$refs['add_item'].show()
		},
		cancelItem() {
			this.$refs['add_item'].hide()
		},
		saveItem() {
			this.staff_selected_array = this.selectedStaff.map(item => item.id)
			if (this.staff_selected_array && this.staff_selected_array.length > 0) {
				let datas = {
					user_id: this.staff_selected_array,
					activity_id: this.$route.params.activity_id
				}
				// console.log('datas: ', datas)
				this.$store
					.dispatch('project/addUserAssign', datas)
					.then(response => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Added successfully!',
								variant: 'success',
							},
						},
							{
								position: 'top-center'
							}
						)
						this.$refs['add_item'].hide()
						this.fetchList()
						this.listParticipant(this.selectedOrganisation)
					})
					.catch((error) => {
						console.log(error)
						this.$toast({
							component: ToastificationContent,
							props: {
								title: error.response.data.message,
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							})
					})
			}
			else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Select Participants field is required',
						variant: 'danger',
					},
				},
					{
						position: 'top-center'
					}
				)
			}
		},
		detailParticipant(item) {
			this.$router.push(`/admin/participant-management/${item.user_id}`)
		}
	}
}
</script>
<style lang="scss">
.Vue-Toastification__container.top-center {
	top: 20px;
}

#addItem_modal .modal-dialog {
	max-width: 650px;
}

.organisations {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-align: center;
}</style>