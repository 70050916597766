<template>
	<section class="py-modal-body-0">
		<b-card class="px-table-0 mt-1 w-15-percent-cl-1 w-25-percent-cl-2 w-15-percent-cl-5 wrap-anywhre">
			<b-row class="align-center">
	            <b-col lg-6>
	            	<div class="text-right mb-2 pr-2">
	            		<template v-if="userData && (permission ? permission.edit : false)">

		                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="addItem">
		                        Add Expert
		                    </b-button>
		                </template>
		                <template v-else>
		                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                        Add Expert
		                    </b-button>
		                </template>
	                </div>
	            </b-col>
	        </b-row>
		    <b-table small :fields="fields_ex_users_experts" :items="users_experts" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="headClicked" @row-clicked="expertsDetail">
		    	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == user_ex_sort.sort_field && user_ex_sort.sort_type == 'desc'}]" style="position: relative; left: 45px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
		    	</template>
		    	<template #head()="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>			
		    	<template #head(name)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
		    	<template #head(status)="data">
		    		<span class='text-center d-block'>{{ data.label }}</span>
		    	</template>	
		    	<template #cell(id)="data">
					<span>{{ data.item.id }}</span>
				</template>	
				<template #cell(name)="data">
					<span class="maxTwoRow">{{ data.item.name }}</span>
				</template>	
				<template #cell(status)="data">
					<span class="d-block text-center" v-if="data.item.status == 0">Inactive</span>
					<span class="d-block text-center" v-else="data.item.status == 1">Active</span>
				</template>		
				<template #cell(action)="data">
					<div class="d-block text-center">	
						<template v-if="userData && (permission ? permission.edit : false)">
							<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.name)">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
								</svg>
							</span>
						</template>
						<template v-else>
							<span class="px-05">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#B1B1B1"/>
								</svg>
							</span>
						</template>
					</div>									
				</template>		
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>				
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			</b-table>								
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="active-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>	
		<b-modal
            ref="add_item"
            title="Add Expert" 
            :no-close-on-backdrop="true"   
            id="addItem_modal">
            <b-row style="padding-right: 30px">                
                <b-col lg="12" class="px-0">
                    <b-form-group label="Expert Type" label-cols-xl="3"
                      label-cols-md="3" label-class="text-lg-right bold label-30-width">
                        <validation-provider
                          #default="{ errors }"
                          name="Expert Type"
                          rules="required"               
                      	>   
                          	<v-select
                          		v-model="selectedType"
                            	label="title"
                            	:options="optionsType"
                            	placeholder="Select a Type"
                            	class="timebox form-control px-0 border-none h-auto"
                          	/>
                          	<small class="text-danger">{{ errors[0] }}</small>
                      	</validation-provider>
                    </b-form-group>
                </b-col> 
                <b-col lg="12" class="px-0">
                    <b-form-group label="Project Expert" label-cols-xl="3"
                      label-cols-md="3" label-class="text-lg-right bold label-30-width">
                        <validation-provider
                          #default="{ errors }"
                          name="Project Expert"
                          rules="required"               
                      	>   
                          	<v-select
                          		v-model="selectedProjectExpert"
                          		:options="optionsProject"
                            	label="eoi_name"
                            	placeholder="Select Expert"
                            	class="timebox form-control px-0 border-none h-auto"
                          	/>
                          	<small class="text-danger">{{ errors[0] }}</small>
                      	</validation-provider>
                    </b-form-group>
                </b-col>   
            </b-row>   
            <template #modal-footer="{ cancel }" class="kim">
                <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelItem">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveItem">
                    Save
                </b-button>
            </template> 
      	</b-modal>
	</section>
</template>

<script>
	import vSelect from 'vue-select'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			ValidationProvider,
        	ValidationObserver,
        	vSelect,
		},	
		props: {
	        permission: {
	            type: Boolean,
	            required: false,
	            default: () => ''
	        },
			permission: {
				type: [Array, String, Number, Object],
			}
	    },
		data(){
			return{
			    fields_ex_users_experts: [
                	{ key: 'id', label: 'ID.' },
			        { key: 'name', label: 'Fullname' },			        
			        { key: 'email', label: 'Email' },			  
			        { key: 'status', label: 'Status' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	users_experts: [],	
			    userData: JSON.parse(localStorage.getItem('userData')),
			    user_ex_sort: {"sort_field" : "id", "sort_type":"asc"},

			    selectedType: null,
			    optionsType: [
			    	{ title: 'External', value: 0 },
			    	{ title: 'Ex-ITE', value: 1 }, 
			    	{ title: 'ITE', value: 2 }, 
			    ],

			    optionsProject: [],
			    selectedProjectExpert: null,

			    tam: [], 
			    tam2: [], 

			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
			}
		},
		mounted () {
		    this.selected = '10'
		},
		watch: {
			selectedType:function(val){
				if(val){
					this.selectedProjectExpert = null
					this.projectExperts(val.value)
				}	
				else{
					this.selectedProjectExpert = null
					this.optionsProject = []
				}			
			},
			currentPage(val){
				this.fetchList()
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchList()
				}
			},
		},
		created() {
			this.fetchList()
		},
		methods: {
			fetchList(){
				this.$store
				.dispatch('project/listUsersExpert', {
					filter: {"activity": this.$route.params.activity_id },
					sort: this.user_ex_sort,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {
					this.users_experts = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			expertsDetail(item){
				 this.$router.push({ path: `/admin/staff-management/TVET-experts/${item.expert_id}` })
			},
			headClicked(key) {
				if(this.user_ex_sort.sort_field == key) {
					if(this.user_ex_sort.sort_type == 'asc') this.user_ex_sort.sort_type = 'desc'
					else this.user_ex_sort.sort_type = 'asc'
				} else {					
					this.user_ex_sort.sort_field = key
					this.user_ex_sort.sort_type = 'desc'
				}
				this.fetchList()
			},
			showDeleteAction(id, title) {
	            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
	              title: 'Delete',
	              size: 'sm',
	              buttonSize: 'sm',
	              okVariant: 'danger',
	              okTitle: 'Delete',
	              cancelTitle: 'Cancel',
				  cancelVariant: 'outline-danger',
	              footerClass: 'p-2 modal-delete',
	              hideHeaderClose: false,
	              centered: true
	            })
	              .then(value => {
	                  if(value){
	                    	this.$store
					        .dispatch('project/deleteExpertItem', {
					            id: id  
					        })
					        .then(response => {
					          	this.$toast({
					              	component: ToastificationContent,
					              	props: {
					                	title: title + ' Has been deleted!',
					                	variant: 'danger',
					              	},
					            },
					            {
					              position: 'top-center'
					            })
					          	this.fetchList()                 
					        })
					        .catch((error) => {
					            console.log(error)
					            this.$toast({
					                    component: ToastificationContent,
					                    props: {
					                        title: error.response.data.message,
					                        variant: 'danger',
					                    },
				                    },
				                    {
				                      position: 'top-center'
				                    })
					        })  
	                  }
	              })
	              .catch(err => {
	                // An error occurred
	              })
	        },
			projectExperts(type){
				this.$store
				.dispatch('project/listExperts', {
					filter: 
					{
					 	"list_search": [
						   	{
						     	"field": "status",
						     	"keyword": "2"  /// 2: lấy những staff đã approve
						     	// "keyword": "1"  /// data to test
						   	},
						   	{
						     	"field": "type",
						     	"keyword": type
						   	}
					 	]
					}
				})
				.then(response => {
					this.tam = response.data.data.map(item => item )
					this.tam2 = this.tam.filter( 
					    val => !this.users_experts.find( arr1Obj => arr1Obj.expert_id === val.id)
					)
					this.optionsProject = this.tam2
					// console.log('options ProjectExpert: ', response.data.data)
					// console.log('tam 2: ', this.tam2)
				})
				.catch((error) => {
					console.log(error)
				})
			},
	        addItem(){
	        	this.selectedType = null
		        this.optionsProject = []
		        this.$refs['add_item'].show()
		    },
		    cancelItem(){
		        this.$refs['add_item'].hide()
		    },
		    saveItem(){
		    	if(this.selectedType && this.selectedProjectExpert){
	        		let datas = {
	        			expert_id: this.selectedProjectExpert.id,
	        			activity_id: this.$route.params.activity_id
	        		}
	        		this.$store
	                .dispatch('project/addExperts', datas)
	                .then(response => {
	                    this.$toast({
	                          component: ToastificationContent,
	                          props: {
	                            title: response.data.message,
	                            variant: 'success',
	                          },
	                      },
	                      {
	                          position: 'top-center'
	                      }
	                    )
	                    this.$refs['add_item'].hide()
	                    this.fetchList()
	                })
	                .catch((error) => {
	                  	console.log(error)
	                  	this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                        	title: error.response.data.message,
	                        	variant: 'danger',
	                      	},
	                    },
	                    {
	                      position: 'top-center'
	                    })
	                })  
	        	}
	        	else{
	                this.$toast({
	                      component: ToastificationContent,
	                      props: {
	                        title: 'Expert Type & Project Expert fields are required',
	                        variant: 'danger',
	                      },
	                  },
	                  {
	                      position: 'top-center'
	                  }
	                )
              	}
		    }
		}
	}
</script>
<style lang="scss">
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
</style>