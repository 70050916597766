<template>
  <b-card class="px-table-0 w-10-percent-cl-1 w-17-percent-cl-2 w-15-percent-cl-3">
    <b-row class="align-center">
      <b-col lg="6" style="padding-left: 33px ">
        <h2 class="mb-2"><strong>Session Evaluation Forms </strong></h2>
      </b-col>
      <b-col lg-6>
        <div class="text-right mb-2 pr-2">
          <template v-if="eva && eva.length > 0">
            <b-button type="button" variant="primary" class="btn-df size-18 black" style="margin-right: 10px"
              @click="exportEva">
              Export
            </b-button>
          </template>

          <template v-if="userData && (permission ? permission.edit : false)">

            <template v-if="nowDate <= endDatePro">
              <b-button type="button" variant="primary" class="btn-df size-18 black" @click="addSession">
                Add Evaluation
              </b-button>
            </template>
            <template v-else>
              <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
                Add Evaluation
              </b-button>
            </template>

          </template>
          <template v-else>
            <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
              Add Evaluation
            </b-button>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-table small :fields="fields_eva" :items="eva" tbody-tr-class="size-14 table-row" show-empty
      @head-clicked="headClicked">
      <template #head()="data">
        <span class="d-block text-center">{{ data.label }}</span>
      </template>
      <template #head(id)="data">
        <span>{{ data.label }}</span>
        <span :class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
          style="position: relative; left: 20px;">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </template>
      <template #head(title)="data">
        <span class="text-center d-block">{{ data.label }}</span>
      </template>
      <template #cell()="data">
        <span class="d-block text-center">{{ data.value }}</span>
      </template>
      <template #cell(id)="data">
        <span v-if="data.field.key == sort.field && sort.type == 'desc'">
          {{ total - data.index }}
        </span>
        <span v-else>
          {{ data.index + 1 }}
        </span>
      </template>
      <template #cell(title)="data">
        <span class="customName text-center">{{ data.item.title }}</span>
      </template>
      <template #cell(presenter_name)="data">
        <template v-if="data.item.presenter_name != null">
          <span class="maxTwoRow mx-auto text-center">{{ data.value.eoi_name }}</span>
        </template>
        <template v-else>
          <span class="text-center d-block">-</span>
        </template>
      </template>
      <template #cell(date)="data">
        <span class="d-block text-center">{{ data.item.date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
      </template>
      <template #cell(evaluation)="data">
        <span class="text-center d-block" style="color: #8471FF ">
          <template v-if="userData && (permission ? permission.edit : false)">
            <span class="cursor-pointer mx-1" @click="editEvaParticipant(data.item.id, data.item.template)">Edit</span>
          </template>
          <span class="cursor-pointer text-decoration-underline mx-1"
            @click="exportPdfItem(data.item.id, data.item.title)" v-b-tooltip.hover title="Click to download">PDF</span>
          <span class="cursor-pointer text-decoration-underline mx-1"
            @click="exportCsvItem(data.item.id, data.item.title)" v-b-tooltip.hover title="Click to download">CSV</span>
        </span>
      </template>
      <template #cell(action)="data">
        <div class="d-block text-center">
          <template v-if="userData && (permission ? permission.edit : false)">

            <template v-if="data.item.status == 1">
              <span class="px-05 cursor-pointer" @click="pauseItem(data.item.id)">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.5 2.40039C6.986 2.40039 2.5 6.88639 2.5 12.4004C2.5 17.9144 6.986 22.4004 12.5 22.4004C18.014 22.4004 22.5 17.9144 22.5 12.4004C22.5 6.88639 18.014 2.40039 12.5 2.40039ZM12.5 20.4004C8.089 20.4004 4.5 16.8114 4.5 12.4004C4.5 7.98939 8.089 4.40039 12.5 4.40039C16.911 4.40039 20.5 7.98939 20.5 12.4004C20.5 16.8114 16.911 20.4004 12.5 20.4004Z"
                    fill="#DD3E47" />
                  <path d="M9.5 9.40039H15.5V15.4004H9.5V9.40039Z" fill="#DD3E47" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="editSession(data.item.id)">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                    fill="#8471FF" />
                </svg>
              </span>
              <template v-if="userData && (permission ? permission.edit : false)">
                <span class="px-05 cursor-pointer" @click="showDeleteAction(data.item.id, data.item.title)">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#DD3E47" />
                  </svg>
                </span>
              </template>
              <template v-else>
                <span class="px-05">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#B1B1B1" />
                  </svg>
                </span>
              </template>
            </template>
            <template v-else-if="data.item.status == 0 || data.item.status == 2">
              <span class="px-05 cursor-pointer" @click="playItem(data.item.id)">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.3 14.3004L13.97 10.8004C14.24 10.6004 14.24 10.2004 13.97 10.0004L9.3 6.50039C8.97 6.25039 8.5 6.49039 8.5 6.90039V13.9004C8.5 14.3104 8.97 14.5504 9.3 14.3004ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                    fill="#29D3B5" />
                </svg>
              </span>
              <span class="px-05 cursor-pointer" @click="editSession(data.item.id)">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                    fill="#8471FF" />
                </svg>
              </span>
              <template v-if="userData && (permission ? permission.edit : false)">
                <span class="px-05 cursor-pointer" @click="showDeleteAction(data.item.id, data.item.title)">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#DD3E47" />
                  </svg>
                </span>
              </template>
              <template v-else>
                <span class="px-05">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="#B1B1B1" />
                  </svg>
                </span>
              </template>
            </template>

          </template>
          <template v-else>
            <span class="px-05">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.3 14.3004L13.97 10.8004C14.24 10.6004 14.24 10.2004 13.97 10.0004L9.3 6.50039C8.97 6.25039 8.5 6.49039 8.5 6.90039V13.9004C8.5 14.3104 8.97 14.5504 9.3 14.3004ZM10.5 0.400391C4.98 0.400391 0.5 4.88039 0.5 10.4004C0.5 15.9204 4.98 20.4004 10.5 20.4004C16.02 20.4004 20.5 15.9204 20.5 10.4004C20.5 4.88039 16.02 0.400391 10.5 0.400391ZM10.5 18.4004C6.09 18.4004 2.5 14.8104 2.5 10.4004C2.5 5.99039 6.09 2.40039 10.5 2.40039C14.91 2.40039 18.5 5.99039 18.5 10.4004C18.5 14.8104 14.91 18.4004 10.5 18.4004Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
            <span class="px-05">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
            <span class="px-05">
              <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                  fill="#B1B1B1" />
              </svg>
            </span>
          </template>
        </div>
      </template>
      <template #empty="scope">
        <h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
      </template>
    </b-table>
    <b-modal id="add-Session" ref="modal_addSession" title="Evaluation Form Creation" hide-header-close ok-title="Save"
      centered no-close-on-backdrop>
      <b-row style="padding-right: 20px ">
        <b-col lg="6" class="px-0">
          <b-form-group label="Session Title" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <b-form-input v-model="evaName" placeholder="Title" :formatter="length100"></b-form-input>
            <template v-if="require_show">
              <small class="text-danger" v-if="!evaName">The Session Title field is required</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Presenter" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="selectedPresenter" label="name" :options="optionPresenter" placeholder="Expert"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!selectedPresenter">The Presenter field is required</small>
            </template>

          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Date From" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <flat-pickr v-model="date_from" placeholder="dd/mm/yy" class="form-control"
              :config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: minScheduleDate }" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!date_from">The Date From field is required</small>
            </template>

          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Template" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="selectedTemplate" label="title" :options="optionTemplate" placeholder="Section Template"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!selectedTemplate">The Template field is required</small>
            </template>

          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelAdd">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveEva">
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal id="edit-Session" ref="modal_editSession" title="Edit Activity Session" hide-header-close ok-title="Save"
      centered no-close-on-backdrop>
      <b-row style="padding-right: 20px ">
        <b-col lg="6" class="px-0">
          <b-form-group label="Session Name" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <b-form-input v-model="itemDetail.title" placeholder="Title" :formatter="length100"></b-form-input>
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.title">The Session Title field is required</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Presenter" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="selectedPresenter_edit" label="name" :options="optionPresenter" placeholder="Expert"
              class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.presenter">The Presenter field is required</small>
            </template>

          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Date From" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <flat-pickr v-model="itemDetail.date" placeholder="Select a date" class="form-control"
              :config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d' }" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!itemDetail.date">The Date From field is required</small>
            </template>
          </b-form-group>
        </b-col>
        <b-col lg="6" class="px-0">
          <b-form-group label="Template" label-cols-xl="4" label-cols-md="4" label-class="text-lg-right bold"
            class="star-required">
            <v-select v-model="selectedTemplate_edit" label="title" :options="optionTemplate"
              placeholder="Section Template" class="timebox black-child-im form-control px-0 border-none" />
            <template v-if="require_show">
              <small class="text-danger" v-if="!selectedTemplate_edit">The Template field is required</small>
            </template>

          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelEdit">
          Cancel
        </b-button>
        <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveEditEva">
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modalList" ref="eva_part_ref" hide-header ok-title="Save" centered>
      <div class="table-custom w-20-percent-cl-1 w-50-percent-cl-2">
        <b-table small :fields="fields_eva_part" :items="eva_part" tbody-tr-class="size-14 table-row" show-empty
          @head-clicked="headClicked_part">
          <template #head()="data">
            <span class="d-block text-center">{{ data.label }}</span>
          </template>
          <template #head(id)="data">
            <span>{{ data.label }}</span>
            <span
              :class="['cursor-pointer iconsort', { sort: data.field.key == sort_part.field && sort_part.type == 'desc' }]"
              style="position: relative; left: 25px;">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </template>
          <template #cell(id)="data">
            <span v-if="data.field.key == sort_part.field && sort_part.type == 'desc'">
              {{ total_part - data.index }}
            </span>
            <span v-else>
              {{ data.index + 1 }}
            </span>
          </template>
          <template #head(name)="data">
            <span>{{ data.label }}</span>
          </template>
          <template #cell(name)="data">
            <span class="maxTwoRow">{{ data.item.participant.full_name }}</span>
          </template>
          <template #cell(action)="data">
            <span class="text-center d-block">
              <b-link @click="evaluation_detail(data.item.id)" target="_blank">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.501 16H2.50098C1.39641 16 0.500977 15.1046 0.500977 14V3C0.500977 1.89543 1.39641 1 2.50098 1H6.50098V3H2.50098V14H13.501V10H15.501V14C15.501 15.1046 14.6055 16 13.501 16ZM8.20098 9.707L6.79098 8.293L13.084 2H9.50098V0H16.501V7H14.501V3.415L8.20098 9.707Z"
                    fill="#8471FF" />
                </svg>
              </b-link>
            </span>
          </template>
          <template #empty="scope">
            <h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
          </template>
        </b-table>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="static" class="btn-default" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>

import Datepicker from 'vuejs-datepicker'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const moment = require('moment')

export default {
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    // per_owner: {
    //   type: Boolean,
    //   required: false,
    //   default: () => ''
    // },
    endDatePro: {
      type: String,
      required: false,
      default: () => ''
    },
    permission: {
      type: [Array, String, Number, Object],
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      fields_eva: [
        { key: 'id', label: 'No.' },
        { key: 'title', label: 'Session Title' },
        { key: 'presenter_name', label: 'TVET Expert' },
        { key: 'date', label: 'Date' },
        { key: 'template_name', label: 'Template Section' },
        { key: 'evaluation', label: 'Evaluations' },
        { key: 'action', label: 'Actions' },
      ],
      eva: [],

      sort: { "field": "id", "type": "asc" },
      total: 0,

      evaName: null,
      date_from: null,

      selectedPresenter: null,
      optionPresenter: [],

      selectedPresenter_edit: {
        name: null,
        value: null
      },

      selectedTemplate: null,
      optionTemplate: [
        { title: 'Session Template 1', value: 1 },
        { title: 'Session Template 2', value: 2 },
      ],

      require_show: false,
      itemDetail: [],

      selectedTemplate_edit: {
        title: null, value: null
      },
      nowDate: null,

      fields_eva_part: [
        { key: 'id', label: 'No.' },
        { key: 'name', label: 'Participant Name' },
        { key: 'action', label: 'Action' },
      ],
      eva_part: [],
      total_part: 0,
      sort_part: { "field": "id", "type": "asc" },
      id_eva_edit_tam: null,
      eva_template: null,
      eva_id: null,
    }
  },
  computed: {
    minScheduleDate() {
      var d = new Date()
      d.setDate(d.getDate())
      var now = moment(d).format('YYYY-MM-DD')
      return now
    },
  },
  created() {
    this.fetchList()
    this.listUsersExpert()
    this.nowDate = moment().format("YYYY-MM-DD")
  },
  methods: {
    length100(e) {
      return String(e).substring(0, 100)
    },
    headClicked(key) {
      if (this.sort.field == key) {
        if (this.sort.type == 'asc') this.sort.type = 'desc'
        else this.sort.type = 'asc'
      } else {
        this.sort.field = key
        this.sort.type = 'desc'
      }
      this.fetchList()
    },
    fetchList() {
      this.$store
        .dispatch('project/listEvaluation', {
          activity_id: this.$route.params.activity_id,
          sort: this.sort,
        })
        .then(response => {
          this.eva = response.data.data
          this.total = response.data.data.length
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listUsersExpert() {
      this.$store
        .dispatch('project/listUsersExpert', {
          filter: { "activity": this.$route.params.activity_id },
          // sort: this.user_ex_sort
        })
        .then(response => {
          this.optionPresenter = response.data.data
          // console.log('Options presenter: ', this.optionPresenter)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportEva() {
      this.$store.dispatch('project/exportEvaluation', {
        id: this.$route.params.activity_id
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Evaluations-list.zip') //or any other extension
          document.body.appendChild(link)
          link.click()

          // console.log('export Eva: ', response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportCsvItem(id, title) {
      this.$store.dispatch('project/exportEvaCsv', {
        id: id
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title + '.csv') //or any other extension
          document.body.appendChild(link)
          link.click()

          // console.log('export Eva: ', response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPdfItem(id, title) {
      this.$store.dispatch('project/exportEvaPdf', {
        id: id
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title + '.pdf') //or any other extension
          document.body.appendChild(link)
          link.click()

          // console.log('export Eva: ', response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    addSession() {
      this.require_show = false

      this.evaName = null
      this.date_from = null
      this.selectedTemplate = null
      this.selectedPresenter = null
      this.$refs['modal_addSession'].show()
    },
    editSession(id) {
      this.require_show = false
      this.detailItem(id)
      this.$refs['modal_editSession'].show()
    },
    detailItem(id) {
      this.$store
        .dispatch('project/detailEva', {
          id: id
        })
        .then(response => {
          this.optionTemplate = [
            { title: 'Session Template 1', value: 1 },
            { title: 'Session Template 2', value: 2 },
          ]
          // this.listUsersExpert()
          this.itemDetail = response.data.data
          if (this.itemDetail && this.itemDetail.presenter_name) {
            this.selectedPresenter_edit.name = this.itemDetail.presenter_name.eoi_name
            this.selectedPresenter_edit.value = this.itemDetail.presenter_name.id
          }
          if (this.itemDetail.template == 1) {
            this.selectedTemplate_edit.title = 'Session Template 1'
            this.selectedTemplate_edit.value = 1
          }
          else if (this.itemDetail.template == 2) {
            this.selectedTemplate_edit.title = 'Session Template 2'
            this.selectedTemplate_edit.value = 2
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveEva() {
      if (this.evaName && this.date_from && this.selectedTemplate && this.selectedPresenter) {
        let datas_eva = {
          title: this.evaName,
          presenter: this.selectedPresenter.expert_id,
          template: this.selectedTemplate.value,
          date: this.date_from,
          activity_id: this.$route.params.activity_id
        }
        this.$store
          .dispatch('project/addEva', datas_eva)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.evaName + ' Created!',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.$refs['modal_addSession'].hide()
            this.fetchList()
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
      else {
        this.require_show = true
      }
    },
    showDeleteAction(id, title) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'outline-danger',
        footerClass: 'p-2 modal-delete',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store
              .dispatch('project/deleteEvaluation', {
                id: id
              })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: title + ' Has been Deleted!',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-center'
                  })

                this.fetchList()
              })
              .catch((error) => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.message,
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-center'
                  })
              })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    cancelAdd() {
      this.itemDetail.title = null
      this.itemDetail.date = null
      this.selectedPresenter_edit = {
        title: null, value: null
      }
      this.selectedTemplate_edit = {
        title: null, value: null
      }
      this.$refs['modal_addSession'].hide()
    },
    cancelEdit() {
      this.itemDetail.title = null
      this.itemDetail.date = null
      this.selectedPresenter_edit = {
        title: null, value: null
      }
      this.selectedTemplate_edit = {
        title: null, value: null
      }
      this.$refs['modal_editSession'].hide()
    },
    saveEditEva() {
      if (this.itemDetail.title && this.itemDetail.date && this.selectedPresenter_edit && this.selectedTemplate_edit) {
        let datas_edit = {
          title: this.itemDetail.title,
          date: this.itemDetail.date,
          presenter: this.selectedPresenter_edit.value ? this.selectedPresenter_edit.value : this.selectedPresenter_edit.expert_id,
          template: this.selectedTemplate_edit ? this.selectedTemplate_edit.value : this.selectedTemplate_edit.value
        }
        // console.log('datas: ', datas_edit)
        this.$store
          .dispatch('project/editEva', {
            id: this.itemDetail.id,
            data: datas_edit
          })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.itemDetail.title + ' Updated!',
                variant: 'success',
              },
            },
              {
                position: 'top-center'
              })
            this.$refs['modal_editSession'].hide()
            this.fetchList()
          })
          .catch((error) => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                variant: 'danger',
              },
            },
              {
                position: 'top-center'
              })
          })
      }
      else {
        this.require_show = true
      }
    },
    playItem(id) {
      this.$store
        .dispatch('project/convertStatusEvaProActivity', {
          id: id,
          status: 1
        })
        .then(response => {
          this.fetchList()
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    pauseItem(id) {
      this.$store
        .dispatch('project/convertStatusEvaProActivity', {
          id: id,
          status: 2
        })
        .then(response => {
          this.fetchList()
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    editEvaParticipant(id, template) {
      this.id_eva_edit_tam = id
      this.eva_template = template

      this.sort_part = { "field": "id", "type": "asc" }
      this.$refs['eva_part_ref'].show()
      this.getlistEvaPart(id)
    },
    getlistEvaPart(id) {
      this.$store
        .dispatch('project/listPartiEval', {
          id: id,
          sort: this.sort_part
        })
        .then(response => {
          this.eva_part = response.data.data
          this.total_part = response.data.data.length
          console.log('this.eva_part: ', response.data.data)
          // api đang object
        })
        .catch((error) => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        })
    },
    headClicked_part(key) {
      if (this.sort_part.field == key) {
        if (this.sort_part.type == 'asc') this.sort_part.type = 'desc'
        else this.sort_part.type = 'asc'
      } else {
        this.sort_part.field = key
        this.sort_part.type = 'desc'
      }
      this.getlistEvaPart(this.id_eva_edit_tam)
    },
    evaluation_detail(id) {
      this.eva_id = id

      console.log('this.eva_id: ', this.eva_id)
      console.log('this.eva_template: ', this.eva_template)

      let routeData = this.$router.resolve({ path: `/admin/our-portfolio/project/evaluation/${id}`, query: { template: this.eva_template } });
      window.open(routeData.href, '_blank')
    },
  }
}

</script>
<style>
#add-Session .modal-dialog,
#edit-Session .modal-dialog {
  max-width: 74%;
}

#modalList .modal-dialog {
  max-width: 600px;
}

.customName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>